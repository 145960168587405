/* Built In Imports */
/* External Imports */
import { Box, Flex, Image } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
// Component
import SimpleButton from '@components/Buttons/SimpleButton';
import SingleImage from '@components/Card/SingleImage';

/* Services */

/**
 * Renders Youth Book Component
 * @param {Object} sectionContent - data from api for the section
 * @param {string} region - Region of the Section
 * @param {string} lang - Language of the Section
 * @returns {ReactElement} - Youth BookComponent
 */
const YouthBook = ({ sectionContent, region, lang }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <Box w="100%" margin={'0 auto'}>
      <Flex
        w="100%"
        backgroundImage={''}
        backgroundPosition="center"
        flexDir={{ md: 'row', sm: 'column', base: 'column' }}
        // alignItems="center"
        backgroundSize={'100%'}
        // justifyContent={{ md: 'space-between', sm: 'center', base: 'center' }}
        backgroundColor="#FAF7F0"
        pt={{ md: '61px', base: '44px' }}
        pb={'20px'}
        mb="30px"
      >
        <Box
        // minW={{ md: '300px', sm: '272.03px', base: '272.03px' }}
        // minH={{ md: '424.61px', sm: '435.88px', base: '435.88px' }}
        >
          {isMobile && (
            <Image
              src={sectionContent[0]?.mobileImage?.url}
              alt={sectionContent[0]?.mobileImage?.alt}
              title={sectionContent[0]?.mobileImage?.title}
              // width={'100%'}
              height="100%"
              mx={{ md: '0', base: 'auto' }}
              w={{ lg: '500px', md: '320px', sm: '300px', base: '100%' }}
              minW={{ lg: '453px', md: '280px', sm: '430px', base: '8%' }}
              minH={{ md: '532.61px', sm: '532.61px', base: '435.88px' }}
              // maxW={{ md: '300px', sm: '300px', base: '272.03px' }}
              // maxH={{ md: '532.61px', sm: '532.61px', base: '435.88px' }}
              objectFit="cover"
              // background="url(Book_mockup_marcinjarka.png)"
              filter="drop-shadow(-39.5097px 16.9701px 91px rgba(0, 0, 0, 0.17)) drop-shadow(-25.6081px 10.9991px 53.294px rgba(0, 0, 0, 0.129074)) drop-shadow(-15.2185px 6.53663px 28.9852px rgba(0, 0, 0, 0.103259)) drop-shadow(-7.90194px 3.39402px 14.7875px rgba(0, 0, 0, 0.085)) drop-shadow(-3.21931px 1.38275px 7.41481px rgba(0, 0, 0, 0.0667407)) drop-shadow(-0.731661px 0.314261px 3.58102px rgba(0, 0, 0, 0.0409259))"
              transform="rotate(0.55deg) !important"
            />
          )}
          {!isMobile && (
            <Image
              src={sectionContent[0]?.image?.url}
              alt={sectionContent[0]?.image?.alt}
              title={sectionContent[0]?.image?.title}
              // width={'100%'}
              height="100%"
              mx={{ md: '0', base: 'auto' }}
              w={{ lg: '500px', md: '320px', sm: '300px', base: '90%' }}
              minW={{ lg: '453px', md: '280px', sm: '430px', base: '8%' }}
              minH={{ md: '532.61px', sm: '532.61px', base: '435.88px' }}
              // maxW={{ md: '300px', sm: '300px', base: '272.03px' }}
              // maxH={{ md: '532.61px', sm: '532.61px', base: '435.88px' }}
              objectFit="cover"
              // background="url(Book_mockup_marcinjarka.png)"
              filter="drop-shadow(-39.5097px 16.9701px 91px rgba(0, 0, 0, 0.17)) drop-shadow(-25.6081px 10.9991px 53.294px rgba(0, 0, 0, 0.129074)) drop-shadow(-15.2185px 6.53663px 28.9852px rgba(0, 0, 0, 0.103259)) drop-shadow(-7.90194px 3.39402px 14.7875px rgba(0, 0, 0, 0.085)) drop-shadow(-3.21931px 1.38275px 7.41481px rgba(0, 0, 0, 0.0667407)) drop-shadow(-0.731661px 0.314261px 3.58102px rgba(0, 0, 0, 0.0409259))"
              transform="rotate(0.55deg) !important"
            />
          )}
        </Box>

        <Box
          ml={{ md: '60.96px', base: '0' }}
          padding="0 10px 0 0"
          maxW="573px"
          mt={{ lg: '117px', md: '81px', sm: '0', base: '0' }}
          // pr={{ md: '250px', sm: '120px', base: '15px' }}
        >
          <Flex flexDir={'column'} p={{ base: '26px', md: '0' }}>
            <Box
              fontFamily="FedraSansStd-A-medium"
              fontStyle="normal"
              fontWeight="500"
              fontSize={{ md: '36px', base: '24px' }}
              lineHeight="45px"
              color="#28231E"
              mx={{ md: 0, sm: '42px', base: '0' }}
            >
              Youth And The Truth Book
            </Box>
            <Box
              mt="15px"
              fontStyle="normal"
              fontWeight="400"
              fontSize="16px"
              lineHeight={{ md: '27px', base: '26px' }}
              mb={{ md: '41px', base: '30px' }}
              mx={{ md: 0, sm: '42px', base: '0' }}
              fontFamily="FedraSansStd-book"
            >
              What happens when millennials meet a mystic? An avalanche of
              queries and an unflinching stream of answers. In this compilation
              of five talks from Youth and Truth events, Sadhguru fields
              questions that are quirky, personal, profound, and shockingly bold
              from university students.
            </Box>
            <Flex
              display={{ md: 'flex', sm: 'block', base: 'block' }}
              mx={{ md: 0, sm: '42px', base: '0' }}
            >
              <Flex
                flex={1}
                // mb={{ md: '0', sm: '24px', base: '24px' }}
                flexDir={{ md: 'column', sm: 'row', base: 'row' }}
                // mb={{ md: '0', sm: '24px', base: '24px' }}
              >
                <Box>
                  {/* <Button
                      background={"none"}
                      _hover={{ background: "none" }}
                      _active={{ background: "none" }}
                      width="148px"
                      height="46px"
                      border="1px solid #CF4520"
                      borderRadius="3px"
                    >
                      Buy now
                    </Button> */}
                  <SimpleButton
                    style={sectionContent[2]}
                    region={region}
                    lang={lang}
                    width="148px"
                    marginbottom="0"
                    className="simpleButton"
                    padding={{ md: '14px 25px', base: '14px 21px' }}
                    mb="0"
                  />
                </Box>
                <Box
                  height="44px"
                  w={{ md: '120px', base: '98px' }}
                  ml={{ md: '0', base: '20px' }}
                >
                  <SingleImage
                    my="20px"
                    w={{ md: '120px', base: '98px' }}
                    sectionContent={sectionContent[3]}
                  />
                </Box>
              </Flex>
              <Flex
                flex={1}
                // mb={{ md: '0', sm: '24px', base: '24px' }}
                flexDir={{ md: 'column', sm: 'row', base: 'row' }}
              >
                <Box>
                  {/* <Button
                    background={"none"}
                    _hover={{ background: "none" }}
                    _active={{ background: "none" }}
                    width="148px"
                    height="46px"
                    border="1px solid #CF4520"
                    borderRadius="3px"
                  >
                    Buy now
                  </Button> */}
                  <SimpleButton
                    style={sectionContent[4]}
                    region={region}
                    lang={lang}
                    width="148px"
                    marginbottom="0"
                    className="simpleButton"
                    padding={{ md: '14px 25px', base: '14px 21px' }}
                    mb="0"
                  />
                </Box>
                <Box
                  height="44px"
                  w={{ md: '120px', base: '98px' }}
                  ml={{ md: '0', base: '20px' }}
                  // mt={{ md: '31px', sm: '0', base: '0' }}
                >
                  <SingleImage
                    my="20px"
                    w={{ md: '120px', base: '98px' }}
                    sectionContent={sectionContent[5]}
                  />
                </Box>
              </Flex>
              <Flex
                flex={1}
                // mb={{ md: '0', sm: '24px', base: '24px' }}
                flexDir={{ md: 'column', sm: 'row', base: 'row' }}
              >
                <Box>
                  {/* <Button
                    background={"none"}
                    _hover={{ background: "none" }}
                    _active={{ background: "none" }}
                    width="148px"
                    height="46px"
                    border="1px solid #CF4520"
                    borderRadius="3px"
                  >
                    Buy now
                  </Button> */}
                  <SimpleButton
                    style={sectionContent[6]}
                    region={region}
                    lang={lang}
                    width="148px"
                    marginbottom="0"
                    className="simpleButton"
                    padding={{ md: '14px 25px', base: '14px 21px' }}
                    mb="0"
                  />
                </Box>
                <Box
                  maxHeight="44px"
                  h="44px"
                  w={{ md: '120px', base: '98px' }}
                  ml={{ md: '0', base: '20px' }}
                  // mt={{ md: '31px', sm: '0', base: '0' }}
                >
                  <SingleImage
                    my="20px"
                    h="44px"
                    w={{ md: '120px', base: '98px' }}
                    sectionContent={sectionContent[7]}
                  />
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default YouthBook;

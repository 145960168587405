/* Built In Imports */
/* External Imports */
import {
    Box, Tab, Table,
    TableContainer, TabList, TabPanel, TabPanels, Tabs, Tbody, Td, Tr
} from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import ContentArea from '@components/Layout/ContentArea';

/**
 * Renders the Planned Gifts Other Ways To Give component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for the section
 * @param {string} lang - Language for the section
 * @returns {ReactElement} Planned Gifts Other Ways To Give component
 */

const PlannedGiftsOtherWaysToGive = ({ plannedGiftTabData, region, lang }) => {
  return (
    <Box my={10}>
      <Tabs variant="unstyled">
        <TabList>
          {plannedGiftTabData.map((itemTab, index) => {
            return (
              <Tab
                key={index}
                _selected={{ color: 'white', bg: 'orange.50' }}
                bgColor="#f5f5f5"
                fontFamily="FedraSansStd-book"
                fontWeight="700"
                padding="15px"
                fontSize={{ sm: '13px', base:'13px', md:'inherit', lg:'inherit' }}
              >
                {itemTab.data[0]?.titleText}
              </Tab>
            );
          })}
        </TabList>
        <TabPanels>
          {plannedGiftTabData.map((itemTab) => {
            return (
              <TabPanel key={1} bgColor="#f5f5f5">
                <Box width="100%" max="960px">
                  {itemTab.data.map((item, ind) => {
                    return (
                      <>
                        {item._modelApiKey === 'structured_text' && (
                          <Box className="event-text">
                            <ContentArea
                              mainContent={item.body}
                              tAlign={item.style}
                              region={region}
                              lang={lang}
                            />
                          </Box>
                        )}
                        {item._modelApiKey === 'json_table' && (
                          <TableContainer>
                            <Table variant="whiteAlpha">
                              <Tbody fontFamily="FedraSansStd-book">
                                {item &&
                                  item.data &&
                                  item.data.map((tr, ind) => {
                                    return (
                                      <Tr
                                        border="1px solid rgb(221 221 221)"
                                        p="0.35em"
                                        fontSize="18px"
                                      >
                                        <Td>{tr.col1}</Td>
                                        <Td>{tr.col2}</Td>
                                        <Td>{tr.col3}</Td>
                                      </Tr>
                                    );
                                  })}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        )}
                      </>
                    );
                  })}
                </Box>
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
      <style jsx global>
        {`
          .tabContainer {
            max-width: 960px !important;
          }
        `}
      </style>
    </Box>
  );
};

export default PlannedGiftsOtherWaysToGive;
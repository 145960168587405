/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Button } from '@chakra-ui/react';
import Masonry from 'react-masonry-css';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
// Component
import MediaArticleCard from '@components/Card/MediaArticleCard';
import SectionTitle from '@components/Headings/SectionTitle';

/* Services */
import { fetchWisdomLandingPageData } from 'services/wisdomService';

/**
 * Renders Youth Book Component
 * @param {Object} sectionContent - data from api for the section
 * @param {string} region - Region of the Section
 * @param {string} lang - Language of the Section
 * @returns {ReactElement} - Youth PopularQuestion
 */
const PopularQues = ({ sectionContent, region, lang }) => {
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    980: 2,
    768: 2,
    736: 1,
  };
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [totalCount, setTotal] = useState(0);
  const [cards, setCards] = useState([]);
  const [loadtext, setLoadtext] = useState('Load More');
  sectionContent[0].titleDecoration = 'scribble';

  const showMoreCards = async () => {
    // setIsLoaderForMore(true);
    setLoadtext('Loading...');
    let moreCards = await fetchWisdomLandingPageData({
      region: region,
      lang: lang,
      start: cards?.length || 0,
      limit: isMobile ? 6 : 9,
      // topic: '',
      topic: 'youth-and-truth-ac',
      slug: 'wisdom',
    });

    if (moreCards?.posts?.cards?.length) {
      setTotal(moreCards.posts.totalCount);
      setCards(prev => [...prev, ...moreCards.posts.cards]);
    }
    setLoadtext('Load More');
  };

  useEffect(() => {
    // consoleLog('useEffect');
    showMoreCards();
  }, []);
  // const cards = sectionContent[0]?.topic?.cards;
  // let data = [
  //   {
  //     "_modelApiKey": "topic_based_wisdom_content",
  //     "title": "Popular Questions",
  //     "topic": {
  //       "urlAlias": "youth-and-truth-ac",
  //       "title": "Youth and Truth AC",
  //       "uuid": "e512a571-cd48-482f-bf78-3df635c02128",
  //       "_modelApiKey": "topic",
  //       "cards": [
  //         {
  //           "title": "Are You Ready For A Serious Relationship?",
  //           "summary": "Is love at first sight possible? Sadhguru looks at the nature of attraction and how it plays out in us.",
  //           "url": "/en/wisdom/video/are-you-ready-for-a-serious-relationship",
  //           "urlAlias": "are-you-ready-for-a-serious-relationship",
  //           "cardImage": {
  //             "alt": "Are You Ready For A Serious Relationship?",
  //             "title": "Are You Ready For A Serious Relationship?",
  //             "url": "https://static.sadhguru.org/d/46272/1654933471-serious-relationship-love.jpg"
  //           },
  //           "contentType": "video",
  //           "uuid": "812462a0-128a-4f9b-8476-571e0cb597a1",
  //           "createdAt": "2022-06-10T07:44:44Z",
  //           "updatedAt": "2022-06-11T07:45:02Z"
  //         },
  //         {
  //           "title": "Virender Sehwag Wants to Know the Truth About the Indian Caste System and How We Can Ensure Inclusiveness and Equality",
  //           "summary": "Virender Sehwag asks Sadhguru to reveal the truth about the Indian caste system, and whether India can become caste-free.",
  //           "url": "/en/wisdom/video/truth-about-indian-caste-system-virender-sehwag-asks",
  //           "urlAlias": "truth-about-indian-caste-system-virender-sehwag-asks",
  //           "cardImage": {
  //             "alt": "Virender Sehwag Wants to Know the Truth About the Indian Caste System and How We Can Ensure Inclusiveness and Equality | Youth and Truth",
  //             "title": "Virender Sehwag Wants to Know the Truth About the Indian Caste System and How We Can Ensure Inclusiveness and Equality | Youth and Truth",
  //             "url": "https://static.sadhguru.org/d/46272/1633178181-1633178180534.jpg"
  //           },
  //           "contentType": "video",
  //           "uuid": "d8684b67-ffd7-4faf-8908-30158677cb1a",
  //           "createdAt": "2018-08-23T07:08:25Z",
  //           "updatedAt": "2022-07-30T06:51:42Z"
  //         },
  //         {
  //           "title": "Are Phones and Social Media Bad For Kids? Juhi Chawla Asks Sadhguru",
  //           "summary": "Juhi Chawla asks Sadhguru whether phone usage among youth can cause problems from a) radiation & b) social media.",
  //           "url": "/en/wisdom/video/phones-social-media-bad-for-kids-juhi-asks-sadhguru",
  //           "urlAlias": "phones-social-media-bad-for-kids-juhi-asks-sadhguru",
  //           "cardImage": {
  //             "alt": "Are Phones & Social Media Bad For Kids? Juhi Chawla Asks Sadhguru",
  //             "title": "Are Phones & Social Media Bad For Kids? Juhi Chawla Asks Sadhguru",
  //             "url": "https://static.sadhguru.org/d/46272/1633178174-1633178173283.jpg"
  //           },
  //           "contentType": "video",
  //           "uuid": "8a449727-0bdb-48f8-a41d-e36e7815ad06",
  //           "createdAt": "2018-08-22T08:52:59Z",
  //           "updatedAt": "2022-07-30T06:44:01Z"
  //         },
  //         {
  //           "title": "Is Being Ambitious A Bad Thing? Ranveer asks Sadhguru",
  //           "summary": "Ranveer Singh wants to know the truth about ambition and he’s asking Sadhguru for answers. ",
  //           "url": "/en/wisdom/video/being-ambitious-bad-ranveer-asks-sadhguru",
  //           "urlAlias": "being-ambitious-bad-ranveer-asks-sadhguru",
  //           "cardImage": {
  //             "alt": "Is Being Ambitious A Bad Thing? Ranveer asks Sadhguru",
  //             "title": "Is Being Ambitious A Bad Thing? Ranveer asks Sadhguru",
  //             "url": "https://static.sadhguru.org/d/46272/1633178166-1633178166099.jpg"
  //           },
  //           "contentType": "video",
  //           "uuid": "ef9f7bb4-bb56-48c1-aa1b-eb2fb9a10700",
  //           "createdAt": "2018-08-22T02:34:52Z",
  //           "updatedAt": "2022-07-30T06:38:21Z"
  //         },
  //         {
  //           "title": "Let us Gossip About Truth! – Youth And Truth",
  //           "summary": "Sadhguru on what truth is and is not, and why we need to start gossiping on a cosmic scale! ",
  //           "url": "/en/wisdom/video/youth-and-truth-gossip-about-truth",
  //           "urlAlias": "youth-and-truth-gossip-about-truth",
  //           "cardImage": {
  //             "alt": "Sadhguru in casual wear | Let’s Gossip About Truth! – Youth And Truth",
  //             "title": "Sadhguru in casual wear | Let’s Gossip About Truth! – Youth And Truth",
  //             "url": "https://static.sadhguru.org/d/46272/1631021008-sadhguru-isha-wisdom-video-image-youth-and-truth-lets-gossip-about-truth.jpg"
  //           },
  //           "contentType": "video",
  //           "uuid": "49dad434-8062-4b59-b878-67437a1519ad",
  //           "createdAt": "2018-08-21T02:31:34Z",
  //           "updatedAt": "2022-07-30T06:34:30Z"
  //         },
  //         {
  //           "title": "Whom to Vote for in 2019? - Sadhguru Speaks",
  //           "summary": "Ayushmann Khurrana wants to know the truth about politics & democracy and he’s asking Sadhguru for answers.",
  //           "url": "/en/wisdom/video/whom-to-vote-2019-elections",
  //           "urlAlias": "whom-to-vote-2019-elections",
  //           "cardImage": {
  //             "alt": "Women in queue holding their voter id's | Whom to Vote for in 2019? - Sadhguru Speaks",
  //             "title": "Women in queue holding their voter id's | Whom to Vote for in 2019? - Sadhguru Speaks",
  //             "url": "https://static.sadhguru.org/d/46272/1633193698-1633193697141.jpg"
  //           },
  //           "contentType": "video",
  //           "uuid": "704d70be-1dfe-4d62-a758-6346dd75afd8",
  //           "createdAt": "2018-08-14T00:31:21Z",
  //           "updatedAt": "2022-07-30T06:31:42Z"
  //         },
  //         {
  //           "title": "Kangana Ranaut in Conversation with Sadhguru [Full Talk]",
  //           "summary": "Watch the entire conversation of Indian Hindi actress, Kangana Ranaut with Sadhguru, which happened on August 8, 2018. ",
  //           "url": "/en/wisdom/video/kangana-ranaut-conversation-sadhguru",
  //           "urlAlias": "kangana-ranaut-conversation-sadhguru",
  //           "cardImage": {
  //             "alt": "Kangana Ranaut in Conversation with Sadhguru [Full Talk]",
  //             "title": "Kangana Ranaut in Conversation with Sadhguru [Full Talk]",
  //             "url": "https://static.sadhguru.org/d/46272/1633193654-1633193653619.jpg"
  //           },
  //           "contentType": "video",
  //           "uuid": "7e38d2a5-2249-415f-93c1-2c7fc39fc1c0",
  //           "createdAt": "2018-08-09T01:20:06Z",
  //           "updatedAt": "2022-07-30T06:30:58Z"
  //         },
  //         {
  //           "title": "Ranveer Singh in conversation with Sadhguru",
  //           "summary": "Watch the full scintillating conversation of Ranveer Singh, the Indian actor who appears in Hindi films, with Sadhguru, which occurred on July 21, 2018. ",
  //           "url": "/en/wisdom/video/live-stream-ranveer-singh-actor-in-conversation-with-sadhguru",
  //           "urlAlias": "live-stream-ranveer-singh-actor-in-conversation-with-sadhguru",
  //           "cardImage": {
  //             "alt": "Ranveer Singh in conversation with Sadhguru",
  //             "title": "Ranveer Singh in conversation with Sadhguru",
  //             "url": "https://static.sadhguru.org/d/46272/1633193256-1633193255518.jpg"
  //           },
  //           "contentType": "video",
  //           "uuid": "1783bd56-b714-474a-9f28-bcbbed772791",
  //           "createdAt": "2018-07-19T02:00:00Z",
  //           "updatedAt": "2022-07-30T06:29:19Z"
  //         },
  //         {
  //           "title": "Rapid Fire Round – Karan Johar with Sadhguru",
  //           "summary": "Sadhguru’s refreshingly personal responses to a volley of “rapid fire” questions launched by Karan Johar reveal some lesser known sides and views of the Mystic.\r\n",
  //           "url": "/en/wisdom/video/rapid-fire-round-karan-johar-sadhguru",
  //           "urlAlias": "rapid-fire-round-karan-johar-sadhguru",
  //           "cardImage": {
  //             "alt": "Rapid Fire Round – Karan Johar with Sadhguru",
  //             "title": null,
  //             "url": "https://static.sadhguru.org/d/46272/1633182621-1633182620397.jpg"
  //           },
  //           "contentType": "video",
  //           "uuid": "4c12784b-0af8-413c-a2cc-b64c9880f0fc",
  //           "createdAt": "2017-06-13T04:11:18Z",
  //           "updatedAt": "2022-07-30T06:24:41Z"
  //         },
  //         {
  //           "title": "Touched By Life, Not Bored by Overload",
  //           "summary": "Sadhguru explains, the generational gap is no longer just about taking a next step, but has become an unprecedented onslaught of information and communication leading to a real threat of psychological overload.\r\n",
  //           "url": "/en/wisdom/video/touched-by-life-not-bored-by-overload",
  //           "urlAlias": "touched-by-life-not-bored-by-overload",
  //           "cardImage": {
  //             "alt": "Touched By Life, Not Bored by Overload",
  //             "title": null,
  //             "url": "https://static.sadhguru.org/d/46272/1635503602-1635503601795.jpg"
  //           },
  //           "contentType": "video",
  //           "uuid": "8b7b92d0-17e6-4025-a505-f8993fc31df0",
  //           "createdAt": "2016-03-14T22:00:09Z",
  //           "updatedAt": "2022-07-30T06:19:27Z"
  //         },
  //         {
  //           "title": "Virender Sehwag & Sadhguru: What It Takes To Win A World Cup",
  //           "summary": "Cricket icon, Virender Sehwag, explores cricket and cricketer with Sadhguru. The Nawab of Najafgarh seeks Sadhguru's input on staying cool in the middle, and handling the pressure of a billion expectations.\r\n",
  //           "url": "/en/wisdom/video/what-it-takes-to-win-a-world-cup",
  //           "urlAlias": "what-it-takes-to-win-a-world-cup",
  //           "cardImage": {
  //             "alt": "What It Takes To Win A World Cup",
  //             "title": null,
  //             "url": "https://static.sadhguru.org/d/46272/1633178467-1633178466577.jpg"
  //           },
  //           "contentType": "video",
  //           "uuid": "2b13f86f-846d-4263-a4c4-31ca8baf1546",
  //           "createdAt": "2015-03-24T02:29:30Z",
  //           "updatedAt": "2022-07-30T06:12:03Z"
  //         },
  //         {
  //           "title": "Stopping vs Making \"The Nation\" | Actor Siddharth with Sadhguru",
  //           "summary": "Actor Siddharth asks Sadhguru about the disastrous habit of certain sections of society who always point out problems but never participate in creating solutions.\r\n",
  //           "url": "/en/wisdom/video/stopping-vs-making-the-nation-actor-siddharth-with-sadhguru",
  //           "urlAlias": "stopping-vs-making-the-nation-actor-siddharth-with-sadhguru",
  //           "cardImage": {
  //             "alt": "Stopping vs Making \"The Nation\" | Actor Siddharth with Sadhguru",
  //             "title": null,
  //             "url": "https://static.sadhguru.org/d/46272/1635499433-1635499432004.jpg"
  //           },
  //           "contentType": "video",
  //           "uuid": "c10839a3-34f3-4cbf-a454-0b5faf0a7b69",
  //           "createdAt": "2014-06-02T22:00:56Z",
  //           "updatedAt": "2022-07-30T05:58:20Z"
  //         }
  //       ]
  //     }
  //   }
  // ];
  // let cards = data[0].topic.cards;

  return (
    <Box
      pos="relative"
      float="left"
      w="100%"
      mt="5vw"
      textAlign="center"
      mb="3vw"
    >
      <Box textAlign="center" mb="1.5rem">
        <SectionTitle titleObj={sectionContent[0]} />
      </Box>
      <Box
        m="0 auto"
        p={{ base: '0 0 0 0', lg: '0 10px 0 0' }}
        pos="relative"
        maxW="1165px"
        left={{ base: '0' }}
      >
        <Masonry breakpointCols={breakpointColumnsObj}>
          {cards &&
            cards?.length > 0 &&
            cards.map((post, i) => {
              return (
                <Box
                  mx="auto"
                  maxW={{ base: '302px', md: '330px', lg: '370px' }}
                >
                  <MediaArticleCard
                    key={i}
                    {...post}
                    lang={lang}
                    // updateCards={updateCards}
                  />
                </Box>
              );
            })}
        </Masonry>

        {totalCount > cards?.length ? (
          <Box m="40px 0" w="100%" display="grid" placeContent="center">
            <Button
              fontWeight={500}
              fontFamily="FedraSansStd-medium"
              fontSize="18px"
              bgColor="#cf4520"
              // h='auto' // ? it defaults to auto
              padding="10px 36px"
              borderRadius="3px"
              color="#faf7f0"
              textDecoration="none"
              _hover={{
                bg: '#000054',
              }}
              onClick={showMoreCards}
            >
              {loadtext}
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default PopularQues;

/* Built In Imports */
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
// Component
import SingleImage from '@components/Card/SingleImage';
import SectionTitle from '@components/Headings/SectionTitle';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders Youth Book Component
 * @param {Object} sectionContent - data from api for the section
 * @param {string} region - Region of the Section
 * @param {string} lang - Language of the Section
 * @returns {ReactElement} - Youth BookComponent
 */
const YntPartnerSection = ({ sectionContent, region, lang }) => {
  return (
    <Box w="100%" margin={'50px auto'}>
      <MidContainer w={'768'}>
        <SectionTitle titleObj={sectionContent[0]} />
        <Flex
          w="100%"
          backgroundImage={''}
          backgroundPosition="center"
          flexDir={{ md: 'row', sm: 'column', base: 'column' }}
          alignItems="center"
          backgroundSize={'100%'}
          justifyContent={{ md: 'space-between', sm: 'center', base: 'center' }}
        >
          <SingleImage
            my=""
            maxWidth={{ md: '100%', sm: '80%', base: '100%' }}
            sectionContent={sectionContent[1]}
          />
          <SingleImage
            my=""
            maxWidth={{ md: '100%', sm: '80%', base: '100%' }}
            sectionContent={sectionContent[2]}
          />
        </Flex>
        <StructuredTextParser
          str={render(sectionContent[3]?.body)}
          region={region}
          lang={lang}
        />
        <Flex
          w="100%"
          backgroundImage={''}
          backgroundPosition="center"
          flexDir={{ md: 'row', sm: 'column', base: 'column' }}
          alignItems="center"
          backgroundSize={'100%'}
          justifyContent={{ md: 'space-between', sm: 'center', base: 'center' }}
        >
          <SingleImage my="" maxWidth="" sectionContent={sectionContent[4]} />
        </Flex>
        <StructuredTextParser
          str={render(sectionContent[5]?.body)}
          region={region}
          lang={lang}
        />
        <Flex
          w="300px"
          maxW="300px"
          backgroundImage={''}
          backgroundPosition="center"
          flexDir={{ md: 'row', sm: 'column', base: 'column' }}
          alignItems="center"
          justifyContent="space-between"
          backgroundSize={'100%'}
          mx="auto"
          // justifyContent={{ md: 'center', sm: 'center', base: 'center' }}
        >
          <SingleImage
            mx="0"
            // my="0"
            h="62px"
            w="121px"
            margin="0 !important"
            maxWidth="auto"
            sectionContent={sectionContent[6]}
          />
          <SingleImage
            mx="0"
            // my="0"
            h="83px"
            margin="0 !important"
            w="121px"
            maxWidth="auto"
            sectionContent={sectionContent[7]}
          />
        </Flex>
      </MidContainer>
    </Box>
  );
};

export default YntPartnerSection;

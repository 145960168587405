/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import { RenderSections } from '@components/UI/Common/SectionCommonUI';
import PopularQues from '@landing_pages/youthAndTruth/PopularQues';
import YntPartnerSection from '@landing_pages/youthAndTruth/YntPartnerSection';
import YouthBook from '@landing_pages/youthAndTruth/YouthBook';
import PlannedGiftsOtherWaysToGive from '@sections/donation/PlannedGiftsOtherWaysToGive';

/* Services */

/**
 * Renders the OthersCommonUI component.
 *
 * @param {Array} pageBody - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} OthersCommonUI Component.
 */

const OthersCommonUI = ({
  pageBody,
  region,
  lang,
  pageConfig,
  csrData,
  currentRegion,
}) => {
  let plannedGiftTabData = [];

  return (
    <Box
      as="section"
      id="centers-section"
      className="container-article btn-center"
    >
      {pageBody?.length
        ? pageBody.map((section, i) => {
          if (
            // section.sectionId === 'tab-index-section' ||
            section.sectionId === 'planned-gifts' ||
            section.sectionId === 'other-ways-to-give' ||
            section.sectionId === 'new-ways-to-give'
          ) {
            plannedGiftTabData.push({
              id: section.sectionId,
              data: section.sectionContent,
            });
            return (
              plannedGiftTabData?.length < 2 && (
                <PlannedGiftsOtherWaysToGive
                  plannedGiftTabData={plannedGiftTabData}
                  region={region}
                  lang={lang}
                />
              )
            );
          } else if (section.sectionId === 'tab-index-section') {
            return <></>;
          } else if (section.sectionId === 'ynt-popular-que') {
            return (
              <PopularQues
                sectionContent={section.sectionContent}
                region={region}
                lang={lang}
              />
            );
          } else if (section.sectionId === 'ynt-book-section') {
            return (
              <YouthBook
                sectionContent={section.sectionContent}
                region={region}
                lang={lang}
              />
            );
          } else if (section.sectionId === 'ynt-partners-section') {
            return (
              <YntPartnerSection
                sectionContent={section.sectionContent}
                region={region}
                lang={lang}
              />
            );
          } else {
            return (
              <RenderSections
                section={section}
                region={region}
                lang={lang}
                pageConfig={pageConfig}
                pType="others"
                key={i}
                csrData={csrData}
                currentRegion={currentRegion}
              />
            );
          }
        })
        : null}
    </Box>
  );
};

export default OthersCommonUI;
